* {
  min-width: 100vw;
  box-sizing: border-box;
}

.App {
  padding: 0;
  margin: 0;
  text-align: center;
  display: grid;
  justify-items: left;
  grid-template-columns: 40% 60%;
  grid-template-rows: 9rem 100vh;
  gap: 0rem;
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header {
  background: linear-gradient(#070583, #170ad0);
  background-color: #000860;
  color: aliceblue;
  grid-column: span 2;
  width: 100%;
  text-align: center;
  font-size: 38pt;
  font-weight: 600;
  padding: 1rem;
  border-bottom: 4px solid rgb(206, 158, 48);
}

.header .subtitle {
  font-size: 22pt;
  font-weight: normal;
  font-style: italic;
  margin-top: 0.5rem;
}

.photo {
  grid-column: 1;
  grid-row: span 3;
  margin: 0;
  background-image: url('../public/img/Paul.jpg');
  background-size: cover;
  min-width: 100%;
}

.photo img {
  display: none;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.content {
  background-color: white;
  font-size: 14pt;
  text-align: justify;
  width: 100%;
  padding: 1rem 2rem;
}

.resume {
  margin: 5rem auto;
  margin-left: 35%;
}

.resume a:hover {
  background-color: white;
  color: black;
  box-shadow: 4px 5px #999;
}

.resume a {
  text-decoration: none;
  font-size: 18pt;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  transition: 0.3s;
}

@media screen and (max-width: 1000px) {
  .App {
    display: flex;
    flex-direction: column;
  }

  .header {
    width: 100%;
    text-align: center;
    font-size: 30pt;
    font-weight: 600;
    padding: 0.7rem;
    border-bottom: 4px solid rgb(206, 158, 48);
  }

  .header .subtitle {
    font-size: 14pt;
    font-weight: normal;
    font-style: italic;
    margin-top: 0.5rem;
  }

  .photo {
    display: inline-flex;
    margin: 0;
    background-image: url('../public/img/Paul.jpg');
    background-size: cover;
    min-height: 600px;
  }

  .resume {
    margin: 5rem auto;
    margin-left: 0;
  }
}
